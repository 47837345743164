import React, { useEffect, Children, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { useNavigate, useParams } from 'react-router-dom'
import { subscribe } from '..//utils/pubsub'
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import RedAlert from '../ui-elements/RedAlert'
import Button from '../formfields/Button'


type Props = {
  handleChange: AnyFunction
  handleCheck: AnyFunction
  validate: AnyFunction
  formFields: ObjectStringKeyAnyValue
  formError: string | null
}

function AddForm({
  handleChange, handleCheck, validate, formFields, formError
}: Props) {


  const {
    resellerOrg
  } = useContext(GlobalContext)


  return <div className={`w-full text-center flex flex-col gap-6`}>


    <div className={`animate-fadein w-full flex flex-col gap-2 items-center`}>
      <h3 className={`font-righteous text-lg`}
        style={{ color: resellerOrg && resellerOrg["PrimaryColor"] ? resellerOrg["PrimaryColor"] : '#161c2e' }}
      >Business name:</h3>
      <input
        className={`w-full bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
        type='text'
        name={`OrganisationName`}
        value={formFields['OrganisationName'] || ''}
        onChange={(e) => handleChange('OrganisationName', e.target.value)}

      />
    </div>

    <div className={`animate-fadein w-full flex flex-col gap-2 items-center`}>
      <h3 className={`font-righteous text-lg`}
        style={{ color: resellerOrg && resellerOrg["PrimaryColor"] ? resellerOrg["PrimaryColor"] : '#161c2e' }}
      >User email address:</h3>
      <input
        className={`w-full bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
        type='text'
        name={`UserEmail`}
        value={formFields['UserEmail'] || ''}
        onChange={(e) => handleChange('UserEmail', e.target.value)}

      />
    </div>


    <div className={`animate-fadein w-full`}>
      <Button
        onClick={validate}
        variant={`primary`}
        size={`big`}
        text={`Add`}
      />
    </div>


    {formError && <RedAlert alignment='left'>{formError}</RedAlert>}

  </div>
}
export default AddForm