import React, { useEffect, Children, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { useNavigate, useParams } from 'react-router-dom'
import { subscribe } from '..//utils/pubsub'
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import RedAlert from '../ui-elements/RedAlert'
import Button from '../formfields/Button'
import AddForm from './AddForm'
import Success from './Success'


type Props = {
}

function AddNewBusiness({
}: Props) {

  const {
    connectionState,
    sendMessageToWebsocket,
    resellerOrg,
    setResellerOrg
  } = useContext(GlobalContext)


  const [savingNewOrg, setSavingNewOrg] = useState(false)
  const [savingNewUser, setSavingNewUser] = useState(false)
  const [formFields, setFormFields] = useState<ObjectStringKeyAnyValue>({})
  const [serverErrorMessage, setServerErrorMessage] = useState<string | null>(null)
  const [formError, setFormError] = useState<string | null>(null)
  const [newOrganisationId, setNewOrganisationId] = useState<string | null>(null)
  const [newOwnerEmail, setNewOwnerEmail] = useState<string | null>(null)
  const navigate = useNavigate()
  const { resellerRecordId } = useParams()




  useEffect(() => {
    if (resellerRecordId && connectionState === 'connected' && !resellerOrg) {
      fetchResellerOrg()
    }
  }, [resellerOrg, connectionState, resellerRecordId])




  useEffect(() => {
    if (
      resellerOrg 
      && resellerRecordId 
      && resellerOrg["OrganisationId"] 
      && !window.location.href.includes('localhost')
    ) {
      const pageLoadData: ObjectStringKeyAnyValue = {
        resellerOrgId: resellerOrg["OrganisationId"],
        resellerRecordId: resellerRecordId,
        userAgent: navigator.userAgent,
      }
      logResellerPageLoad(pageLoadData)
    }
  }, [resellerOrg])


  const logResellerPageLoad = async (pageLoadData: ObjectStringKeyAnyValue) => {
    const payload = JSON.stringify({
      action: 'signup',
      subAction: 'logResellerPageLoad',
      resellerOrgId: pageLoadData.resellerOrgId,
      resellerRecordId: pageLoadData.resellerRecordId,
      userAgent: pageLoadData.userAgent
    })
    // console.log(payload)
    sendMessageToWebsocket(payload)
  }

  const fetchResellerOrg = async () => {
    //console.log(`fetching reseller org ${resellerRecordId}`)
    const payload = JSON.stringify({
      action: 'signup',
      subAction: 'fetchResellerOrg',
      resellerRecordId: resellerRecordId
    })
    sendMessageToWebsocket(payload)

    const unsubscribe = subscribe('fetchResellerOrgResponse', (data: any) => {
      //console.log(data)
      if (data['error']) {
        setResellerOrg({})
        setServerErrorMessage(data['error'])
      } else {
        setServerErrorMessage(null)
        setResellerOrg(data)
      }
      unsubscribe()
    })

  }

  const handleChange = (key: string, value: any) => {
    const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
    newFieldValues[key] = value
    setFormFields(newFieldValues)
  }

  const handleCheck = (event: any) => {
    console.log(event.target)
    const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
    const isChecked = event.target.checked ? true : false
    const value = event.target.name
    let optionsArray = newFieldValues['ChecklistsToAdd'] || []
    if (isChecked === true && !optionsArray.includes(value)) {
      optionsArray.push(value)
    } else if (isChecked === false) {
      optionsArray = optionsArray.filter((option: string) => { return option !== value })
    }
    newFieldValues['ChecklistsToAdd'] = optionsArray
    setFormFields(newFieldValues)
  }

  const saveNewBusiness = async () => {
    setSavingNewOrg(true)
    const payload: ObjectStringKeyAnyValue = {
      action: "signup",
      values: formFields
    }

    if (resellerRecordId && resellerOrg && resellerOrg["OrganisationId"]) {
      // if reseller org id exists and is valid, add to payload
      payload['subAction'] = 'cloneFromReseller'
      payload['organisationToCloneFrom'] = resellerOrg["OrganisationId"]
      payload['StripePricePlan'] = resellerOrg["StripePricePlan"]
    }

    //console.log(payload)
    sendMessageToWebsocket(JSON.stringify(payload))
    const unsubscribe = subscribe("saveSignupResponse", data => {
      //console.log(data)
      if (data.OrganisationId && data.UserEmail) {
        setNewOrganisationId(data.OrganisationId)
        setNewOwnerEmail(data.UserEmail)
        setSavingNewOrg(false)
      }
      unsubscribe()
    })
  }


  const validate = () => {
    let emailRegex = new RegExp("^.+@.+")
    if (
      formFields['OrganisationName'] && formFields['OrganisationName'].length > '2' &&
      formFields['UserEmail'] && emailRegex.test(formFields['UserEmail'])
    ) {
      saveNewBusiness()
    } else {
      setFormError('Please enter a business name and a valid email address')
    }
  }

  const updateUser = async (UserEmail: string | undefined | null, OrganisationId: string | undefined | null) => {
    if (UserEmail && OrganisationId) {
      setSavingNewUser(true)
      const payload = JSON.stringify({
        action: "signup",
        subAction: 'updateUser',
        values: {
          OrganisationId,
          UserEmail
        }
      })
      //console.log(payload)
      sendMessageToWebsocket(payload)
      const unsubscribe = subscribe("updateUserResponse", data => {
        //console.log(data)
        if (data.OrganisationId && data.UserEmail) {
          //console.log(`updating org id ${data.OrganisationId} from ${data.OldEmail} to ${data.UserEmail}`)
          setNewOwnerEmail(data.UserEmail)
          setSavingNewUser(false)
        }
        unsubscribe()
      })
    }
    else {
      console.log('⛔️ error - no org id or email')
    }
  }




  return <div className={`px-4 py-6 w-full max-w-xl flex flex-col gap-8 items-center`}>

    {savingNewOrg && <Modal
      showCloseButton={true}
      setShowModal={setSavingNewOrg}
    ><Spinner><p>Setting up your organisation. This may take a couple of minutes.</p></Spinner>
    </Modal>}

    {savingNewUser && <Modal
      showCloseButton={true}
      setShowModal={setSavingNewOrg}
    ><Spinner><p>Updating user email...</p></Spinner>
    </Modal>}


    {newOrganisationId && newOwnerEmail && <Success
      userEmail={newOwnerEmail}
      organisationName={formFields['OrganisationName']}
      newOrganisationId={newOrganisationId}
      updateUser={updateUser}
      setSavingNewUser={setSavingNewUser}
      savingNewUser={savingNewUser}
    />}


    {(!newOrganisationId || !newOwnerEmail) && <div className={`w-full text-center flex flex-col gap-6`}>


      {resellerRecordId && !resellerOrg && <Spinner>Loading...</Spinner>}
      {resellerRecordId && resellerOrg && <div>


        <h3 className={`font-righteous text-brandblue text-3xl`}
          style={{ color: resellerOrg && resellerOrg["PrimaryColor"] ? resellerOrg["PrimaryColor"] : '#161c2e' }}
        >{resellerOrg["OrganisationName"]}
        </h3></div>}


      {!resellerRecordId && <h3 className={`font-righteous text-3xl text-brandblue`}>
        Set up a new ChecQR Account</h3>}


      {serverErrorMessage && <RedAlert>{serverErrorMessage}</RedAlert>}

      {resellerOrg && resellerOrg["Id"] && (
        !resellerOrg["OrganisationId"] ||
        !resellerOrg["OrganisationName"] ||
        !resellerOrg["StripePricePlan"]
      ) && <RedAlert>Reseller record has been found but organisation ID, organisation name or Stripe plan is missing.</RedAlert>}


      {(!resellerRecordId || (
        resellerRecordId &&
        resellerOrg &&
        resellerOrg["OrganisationId"] &&
        resellerOrg["OrganisationName"] &&
        resellerOrg["StripePricePlan"]
      )) && <AddForm
          validate={validate}
          handleCheck={handleCheck}
          handleChange={handleChange}
          formFields={formFields}
          formError={formError}
        />}



    </div>}


  </div>
}
export default AddNewBusiness