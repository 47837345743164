import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import CloudformationOutputs from '../CloudformationOutputs.json'
import { Link, useNavigate } from "react-router-dom"
import Modal from './Modal'
import Button from '../formfields/Button'
import { subscribe } from "../utils/pubsub"
import Spinner from './Spinner'

type ObjectAny = {
  [key: string]: any
}

type Props = {
}

function Header({
}: Props) {

  const {
    resellerOrg,
    sendMessageToWebsocket
  } = useContext(GlobalContext)



  return <header
    className={`w-full z-50 flex flex-col items-center bg-brandblue`}
    style={{ backgroundColor: resellerOrg && resellerOrg["BannerColor"] ? resellerOrg["BannerColor"] : '#161c2e'}}
  >

    <div className={`max-w-3xl w-full p-4 flex flex-row items-center justify-center gap-5`}>
      <Link to={'/'}>
        <img
          src={
            resellerOrg && resellerOrg["LogoFileName"] ?
              `https://${CloudformationOutputs.ImageBucketName}.s3.eu-west-1.amazonaws.com/${resellerOrg.LogoFileName}`
              :
              `/logos/logo-white-no-strapline.svg`}
          alt='ChecQR Logo'
          className={`border-0`}
          style={{ minWidth: '50px', maxWidth: '200px', minHeight: '50px', maxHeight: '80px' }}
        />
      </Link>

    </div>

  </header>
}
export default Header