
import React, { useEffect, useState, Children, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from "react-router-dom"

type Props = {
}

function Footer({
}: Props) {



  const {
    resellerOrg
  } = useContext(GlobalContext)

  return <footer
    className={`w-full flex justify-center bg-gray-300 text-gray-700`}>
    <div className={`max-w-3xl w-full p-5 flex flex-row justify-between items-center gap-6`}>

      <div className={`text-xs`}>
      &copy; W&nbsp;O&nbsp;Lahr Ltd.
      </div>

      <div className={'flex flex-row gap-4 items-center'}>
        <a href='https://www.linkedin.com/company/93777705/admin/feed/posts/'><img className={`h-6 opacity-40`} src='/icons/social-linkedin.svg' alt='LinkedIn' /></a>
        {/* <a href=''><img className={`h-6`} src='/social-twitter.svg' alt='Twitter' /></a>
        <a href=''><img className={`h-6`} src='/social-instagram.svg' alt='Instagram' /></a> */}
        <a href='https://www.youtube.com/@ChecQR'><img className={`h-6 opacity-40`} src='/icons/social-youtube.svg' alt='YouTube' /></a>
      </div>

    </div>
  </footer>
}
export default Footer